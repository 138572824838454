@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');


html {
    box-sizing: border-box;
}
 
  
*,
*::before,
*::after {
box-sizing: inherit;
}


a {
    color:inherit;
    text-decoration: none;
}


img {
    max-width: 100%;
}


body {
    min-width: 320px;
    font-family: 'Onest', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    background-color: white;
    color: navy;
    position: relative;
}


:root{
    --gap: 20px;
}


/* glob */
    .list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}


.btn-reset {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.flex {
    display: flex;
}


.container {
    max-width: 1420px;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    width: 100%;
}


.section-title {
    margin: 0;
    /* margin-bottom: 40px; */
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
    /* text-align: center; */
}


.section-descr {
    margin: 0;
    margin-bottom: 50px;
}


.section-offset {
    padding-top: 87px;
    padding-bottom: 160px;
/* background-image: url(../../src/img/photo_gallery/phglr_1.jpg); */
}


.page-title{
    margin: 0;
    font-size: 28px;
    padding-bottom: 40px;
}

/* glob */

.logo-main {
    width: 50%;
    height: auto;
}

.img-container {
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding-top: 20vh;
}

/* header */
.header {
    position: absolute;
    /* position: -webkit-sticky;
    position: sticky; */
    top: 0;
    left: 0;
    /* padding-top: 50px; */
    width: 100%;
    height: auto;
    z-index: 1000;
    display: flex; /* Добавляем flex-контейнер */
    justify-content: center; /* Центрируем содержимое по горизонтали */
    background-color: rgb(49,138,197);
    border: 1px solid rgba(255, 255, 255, 0.001);
    backdrop-filter: blur(5px);

}

.header-container {
    min-width: 320px;
    max-height: 100px;
    align-items: center;
    padding: 25px 40px;
    /* border-radius: 500px; */
    justify-content: space-between;
}

.header-nav {
    align-items: center;
}

.header-list-contacts {
    display: none;
}

.header-logo {
    align-items: center;
}

.classic-logo {
   transform: scale(70%);
}

.alt-logo {
    display: none;
}

.header-list {
    width: 520px;
    gap: 32px;
    font-size: 20px;
    align-items: center;
    justify-content: center;
}

.burger {
    display: none;
    cursor: pointer;
}

.header-item-txt {
    position: relative;
    cursor: pointer;
    color:  white;
}

.header-item-txt::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    /* top: 5px; */
    width: 100%;
    height: 1px;
    background-color:white;
    transform: scaleX(0);
    transition: transform 0.3s;
}

.header-list-item {
    cursor: pointer!important;
    display: flex;
    align-items: center;
    font-optical-sizing: auto;
    font-weight: 300;
}

.header-item-txt:hover::after {
    transform: scaleX(1);
}

@media (max-width: 1272px) {
    .description-btn-sec.mob {
        display: block;
    }

    .description-btn-sec {
        display: none;
    }
    
    .header-nav {
        display: none;
    }

    .header-nav.active {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        min-height: 1500%;
        left: 0;
        top: 99px;
        z-index: 1000;
        background-color: rgb(49,138,197, 0.9);
        color: #F4F4F4;
        animation: navAnimation 0.3s;
        margin: 0;
        padding: 0;
        overflow-y: hidden;
    }

    .header-nav.active ul li {
        font-size: 35px;
    }

    .header-list {
        padding-top: 5vw;
        overflow-y: hidden;

    }

    .header-list-contacts {
        display: block;
        text-align: center;
    }

    .header-item-txt {
        font-size: 35px;
        text-transform: uppercase;
    }

    .header-nav.active ul {
        align-items: center;
    }
    .header-list {
        flex-direction: column;
        row-gap: 30px;
    }

    @keyframes navAnimation {
        from {opacity: 0}
        to {opacity: 1}
    }

    .burger {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        min-width: 60px;
        height: 60px;
        color: white;
        z-index: 50;
    }

    .burger-line {
        background-color: currentColor;
        transform: scale(1);
        width: 80%;
        height: 2px;
        border-radius: inherit;
    }

    .burger::before, .burger::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 80%;
        background-color: currentColor;
        transition: all 0.3s;
    }

    .burger::before { top: 15px }
    .burger::after { bottom: 15px }

    .burger.active span {
        transform: scale(0);
        transition: 0.3s ease-in-out;
    }

    .burger span {
        transition: 0.3s ease-in-out;
    }

    .burger.active::before { 
        top: 50%;
        transform: rotate(45deg) translate(0,-50%);
        transition: 0.3s ease-in-out;
    }
    .burger.active::after {
        top: 50%;
        transform: rotate(-45deg) translate(0,50%);
        transition: 0.3s ease-in-out;
    }

    .header-list-item {
        display: flex;
        justify-content: center;
    }

    .header-list-item a {
        text-align: center;
    }
}

@media (max-width:540px) {
    .header-item-txt::after {
        display: none;
    }

    .header-list-contacts ul li{
        font-size: 16px!important;
        width: 70%;
    }

    .header-list-contacts h3 {
        margin-top: 0;
    }

    .header-item-txt {
        font-size: 22px;
    }

    .advantages-title-part {
        padding-left: 10vw!important;
    }
}

@media (max-height: 450px) {
    .header-nav,
    .header-nav.active {
        max-height: 65vh;
        overflow-y: auto;
    }
}

.description-btn-sec {
    justify-content: center;
    color: white;
}

.description-btn-sec.mob {
    display: none;
    color: white;
    font-size: 20px;
}

.description-btn {
    font-size: 16px;
    font-weight: 300;
    color: white;
    padding: 10px 40px;
    border: 2px solid white;
    border-radius: 100px;
    display: flex;
    transition: 0.3s ease-in-out;
}

.description-btn:hover {
    color:rgb(49,138,197);
    background-color: white;
    transition: 0.3s ease-in-out;
}

.arrow {
    transition: 0.3s ease-in-out;
    margin-left: 10px;
    transform: scale(0.6);
}
  
.arrow.active {
    transform: scale(0.6) rotate(-45deg);
    transition: 0.3s ease-in-out;

}

.arrow-header.active path {
    stroke: rgb(49,138,197);
    transition: 0.3s ease-in-out;
}
